import styled from 'styled-components'

import { ButtonLink, Container, Icon, Text } from 'common/UI'
import { ClinicProcessStoryblok } from 'common/types'
import { addAlphaToColor, dashedLine } from 'common/utils/style'
import { CMSLink } from 'modules/shared'

import { ClinicSectionHeader } from './ClinicSectionHeader'
import { Editable } from './Editable'

type Props = {
  block: ClinicProcessStoryblok
}

export const ClinicProcess = ({ block, ...props }: Props): JSX.Element => {
  return (
    <div {...props}>
      <ClinicSectionHeader
        block={{
          variant: 'default',
          title: block.title,
          description: block.description,
          tag: block.tag,
          _uid: block._uid,
          component: 'clinic-section-header',
        }}
      />
      <Container>
        <Wrapper>
          {block.entry.map((el, i) => {
            const isFirst = i === 0
            return (
              <Editable block={el} key={el._uid}>
                <Item>
                  <WrapperIcon>
                    <div>
                      <Icon icon={el.icon} size={46} />
                    </div>
                    <Line />
                  </WrapperIcon>
                  <InnerWrapper>
                    <div>
                      <Text as="p" variant="twenty/regular">
                        {el.title}
                      </Text>
                      <Text as="p" variant="eighteen">
                        {el.description}
                      </Text>
                    </div>
                    {el.button && isFirst && el.button[0].link && (
                      <ButtonLink
                        href={`tel:${el.button[0].link.url}`}
                        variant="accent"
                        leftIcon="phone"
                        css={{ maxWidth: 'fit-content' }}
                      >
                        {el.button[0].label}
                      </ButtonLink>
                    )}
                    {el.button && !isFirst && el.button[0].link && (
                      <StyledCMSLink
                        href={el.button[0].link}
                        variant="ghost"
                        css={{ maxWidth: 'fit-content' }}
                      >
                        {el.button[0].label}
                      </StyledCMSLink>
                    )}
                  </InnerWrapper>
                </Item>
              </Editable>
            )
          })}
        </Wrapper>
      </Container>
    </div>
  )
}

const Wrapper = styled.div`
  display: grid;

  grid-template-columns: repeat(1, 1fr);

  gap: 1.25rem;

  padding-bottom: 1.25rem;

  ${({ theme }) => theme.media.md} {
    gap: 2.5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-columns: repeat(4, 1fr);
    padding-bottom: 3.75rem;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

const Item = styled.div`
  display: flex;
  gap: 1.25rem;

  ${({ theme }) => theme.media.md} {
    flex-direction: column;
  }
`

const WrapperIcon = styled.div`
  display: flex;
  flex-direction: column;
`

const Line = styled.div`
  margin-top: 1.25rem;
  height: 100%;
  ${({ theme }) =>
    dashedLine('left', addAlphaToColor(theme.colors.foreground.default, 60))};
  background-position: center;

  ${({ theme }) => theme.media.md} {
    display: none;
  }
`

const StyledCMSLink = styled(CMSLink)`
  margin-bottom: 1.25rem;

  ${({ theme }) => theme.media.lg} {
    margin-bottom: 0rem;
  }
`
